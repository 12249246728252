.icon {
    width: $avatar-size-base;
    height: $avatar-size-base;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.3rem;
    line-height: normal;
}
.icon-xxs {
    width: $avatar-size-xxs;
    height: $avatar-size-xxs;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 75%;
    line-height: normal;
}

.icon-xs {
    width: $avatar-size-xs;
    height: $avatar-size-xs;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    line-height: 1;
}

.icon-sm {
    width: $avatar-size-sm;
    height: $avatar-size-sm;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1;
}

.icon-lg {
    width: $avatar-size-lg;
    height: $avatar-size-lg;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1;
}

.icon-xl {
    width: $avatar-size-xl;
    height: $avatar-size-xl;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2.2rem;
    line-height: 1;
}
.icon-2xl {
    width: $avatar-size-xxl;
    height: $avatar-size-xxl;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2.2rem;
    line-height: 1;
}
.icon-3xl {
    width: $avatar-size-xxxl;
    height: $avatar-size-xxxl;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2.2rem;
    line-height: 1;
}