.swiper-center-scale {
	.swiper-slide {
		transform: scale(0.85);
		transition: ease all 0.35s;
		&.swiper-slide-active {
			transform: scale(1);
		}
	}
}


:root {
    --swiper-theme-color: var(--#{$prefix}primary);
}

.swiper-arrow-style-01 {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    text-align: center;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    right: 10px;
    transition: ease all 0.35s;
    font-size: 14px;
    width: 30px;
    height: 30px;
    background: var(--#{$prefix}body-bg);
    color: var(--#{$prefix}body-text);
    border: 1px solid var(--#{$prefix}gray-300);
    border-radius: 5px;
    &.swiper-button-disabled {
        opacity: 0.5 !important;
        pointer-events: none;
    }

    &:hover {
        background: var(--#{$prefix}primary);
        color: var(--#{$prefix}white);
        border-color: var(--#{$prefix}primary);
    }

    .swiper-hover-arrow & {
        opacity: 0;
    }

    .swiper-hover-arrow:hover & {
        opacity: 1;
    }

    &.swiper-prev {
        right: 46px;
    }
}


.swiper-arrow-style-02 {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: var(--#{$prefix}primary);
    border: 2px solid var(--#{$prefix}primary);
    color: var(--#{$prefix}white);
    z-index: 1;
    text-align: center;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: ease all 0.35s;
    font-size: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    &.swiper-button-disabled {
        .swiper-hover-arrow:hover & {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &:hover {
        background: var(--#{$prefix}dark);
        color: var(--#{$prefix}white);
    }

    .swiper-hover-arrow & {
        opacity: 0;
    }

    .swiper-hover-arrow:hover & {
        opacity: 1;
    }

    &.swiper-next {
        right: 15px;
    }

    &.swiper-prev {
        left: 15px;
    }
}


.swiper-arrow-style-03 {
    text-align: center;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    right: 10px;
    transition: ease all 0.35s;
    font-size: 14px;
    width: 30px;
    height: 30px;
    background: var(--#{$prefix}body-bg);
    color: var(--#{$prefix}body-text);
    border: 1px solid var(--#{$prefix}gray-300);
    border-radius: 5px;
    &.swiper-button-disabled {
        opacity: 0.5 !important;
        pointer-events: none;
    }

    &:hover {
        background: var(--#{$prefix}primary);
        color: var(--#{$prefix}white);
        border-color: var(--#{$prefix}primary);
    }

    .swiper-hover-arrow & {
        opacity: 0;
    }

    .swiper-hover-arrow:hover & {
        opacity: 1;
    }
}


.swiper-arrow-style-04 {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: var(--#{$prefix}primary);
    border: 2px solid var(--#{$prefix}primary);
    color: var(--#{$prefix}white);
    z-index: 1;
    text-align: center;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: ease all 0.35s;
    font-size: 16px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    &.swiper-button-disabled {
        .swiper-hover-arrow:hover & {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &:hover {
        background: var(--#{$prefix}dark);
        color: var(--#{$prefix}white);
    }

    .swiper-hover-arrow & {
        opacity: 0;
    }

    .swiper-hover-arrow:hover & {
        opacity: 1;
    }

    &.swiper-next {
        right: 15px;
    }

    &.swiper-prev {
        left: 15px;
    }
}


.swiper-pagination {
    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        border: 1px solid var(--#{$prefix}emphasis-color);
        background: transparent;
        opacity: 0.3;
        transition: ease all 0.35s;
        border-radius: 10px;
        &.swiper-pagination-bullet-active,
        &:hover {
            background: var(--#{$prefix}primary);
            border: 1px solid var(--#{$prefix}primary);
            opacity: 1;
        }
        &.swiper-pagination-bullet-active {
        	width: 25px;
        }
    }
}

.swiper {
    &.swiper-no-scroll {
        overflow: inherit;
    }   
}