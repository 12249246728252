// Button With Icons
.btn {
	text-transform: uppercase;
	letter-spacing: 2px;
	.btn-icon {
		&:first-child {
			padding-right: 0.5rem;
		}
		&:last-child {
			padding-left: 0.5rem;
		}
	}
}


// Button With Shadow
.btn-shadow {
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.show {
        box-shadow: 0 .25rem .25rem .125rem rgba(var(--#{$prefix}btn-focus-shadow-rgb), .1),
        0 .375rem .75rem -.125rem rgba(var(--#{$prefix}btn-focus-shadow-rgb), .4);
    }
}


// Soft Buttons
@each $color, $value in $theme-colors {
    $color-rgb: to-rgb($value);
    .btn-#{$color}-soft {
    	--#{$prefix}btn-color: var(--#{$prefix}#{$color});
	    --#{$prefix}btn-bg: rgba(var(--#{$prefix}#{$color}-rgb), 0.1);
	    --#{$prefix}btn-border-color: transparent;
	    --#{$prefix}btn-hover-color: var(--#{$prefix}white);
	    --#{$prefix}btn-hover-bg: var(--#{$prefix}#{$color});
	    --#{$prefix}btn-hover-border-color: var(--#{$prefix}#{$color});
	    --#{$prefix}btn-active-color: var(--#{$prefix}white);
	    --#{$prefix}btn-active-bg: var(--#{$prefix}#{$color});
	    --#{$prefix}btn-active-shadow: unset;
	    --#{$prefix}btn-focus-shadow-rgb: var(--#{$prefix}#{$color}-rgb);
	    --#{$prefix}btn-active-border-color: var(--#{$prefix}#{$color});
	    --#{$prefix}btn-disabled-color: var(--#{$prefix}#{$color});
	    --#{$prefix}btn-disabled-bg: rgba(var(--#{$prefix}#{$color}-rgb), 0.1);
	    --#{$prefix}btn-disabled-border-color: rgba(var(--#{$prefix}#{$color}-rgb), 0.1);
    }
}

.btn-light-soft {
	--#{$prefix}btn-hover-color: var(--#{$prefix}dark);
	--#{$prefix}btn-active-color: var(--#{$prefix}dark);
}

