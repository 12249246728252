.link-effect {
	position: relative;
	padding: 0;
	border: none;
	background: none;
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: auto;
		width: 100%;
		height: 1px;
		background: currentColor;
		transition: ease all 0.35s;
	}
	&:hover {
		&:after {
			width: 0;
			right: 0;
			left: auto;
		}
	}
}

